<template>
  <div>
    <vs-collapse v-if="purchases_data.length !== 0" accordion>
      <purchase
        v-for="(purchase, index) in purchases_data"
        :key="index"
        :purchase_obj="purchase"
        :course="course"
      ></purchase>
    </vs-collapse>
  </div>
</template>

<script>
import purchase from "./purchase.vue";
export default {
  components: {
    purchase,
  },
  props: {
    course: {
      type: String,
      required: true,
    },
    purchases_data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
