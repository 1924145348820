<template>
  <vs-collapse-item color="primary">
    <div slot="header" :style="{ color: headerColor }">
      {{ purchase_obj.purchase.identity }},
      <p style="display: inline" v-if="course === 'CFA'"> CFA {{purchase_obj.enrollment.cfa_selected_level}},</p>
      <p style="display: inline" v-if="course === 'FRM'"> FRM {{purchase_obj.enrollment.frm_selected_part}},</p>
      {{ purchase_obj.purchase.person_name }}. Enrolled:
      {{ tsToDate(purchase_obj.enrollment.enrollment_date) }}
      <vs-chip
        color="#dddddd"
        style="margin-right: 2%; color:black"
        v-if="purchase_obj.purchase.type === 'Additional Purchase'"
      >
        <b>Additional Purchase</b>
      </vs-chip>
      <!-- Paid: {{ tsToDate(purchase_obj.purchase.date_of_payment_completion) }}. -->
    </div>
    <vs-row v-if="headerColor != 'green'">
      <vs-col
        v-if="showDispatchButton"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
      >
        <vs-button
          color="warning"
          type="filled"
          icon="call_made"
          @click="act('dispatch')"
          >Dispatch</vs-button
        >
      </vs-col>
       <vs-col
        v-if="showDispatchButton"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
      >
        <vs-button
          color="warning"
          type="filled"
          icon="print"
          @click="popupPrint=true"
          >Print</vs-button
        >
      </vs-col>
      <vs-col
        v-if="showCourierNumberButton"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
      >
        <vs-button
          color="warning"
          type="filled"
          icon="info"
          @click="act('updateNumber')"
          >Courier #</vs-button
        >
      </vs-col>
      <vs-col
        v-if="showReturnedButton"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
      >
        <vs-button
          color="danger"
          type="filled"
          icon="call_received"
          @click="act('return')"
          >Returned</vs-button
        >
      </vs-col>
      <vs-col
        v-if="showDeliveryButton"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
      >
        <vs-button
          color="success"
          type="filled"
          icon="star"
          @click="act('deliver')"
          >Delivered</vs-button
        >
      </vs-col>
    </vs-row>
    <book
      v-for="book in purchase_obj.deliverables"
      :key="book.id"
      :book="book"
      @book-selection="bookSelection"
    ></book>

    <vs-popup :title="popupTitle" :active.sync="popupActivo">
      <vs-table data="[]">
        <vs-tr>
          <vs-td>Name</vs-td>
          <vs-td>{{ purchase_obj.purchase.person_name }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>Can-ID</vs-td>
          <vs-td>{{ purchase_obj.purchase.identity }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>Mobile</vs-td>
          <vs-td>{{ purchase_obj.phone_number.last_ten_digits }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>E-Mail</vs-td>
          <vs-td>{{ purchase_obj.email.email }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>Address</vs-td>
          <vs-td>{{ purchase_obj.enrollment.shipping_address }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td><b>Books</b></vs-td>
          <vs-td
            ><b>{{ booksToDisplay }}</b></vs-td
          >
        </vs-tr>
         <vs-tr v-if="displayCourierNumberInput">
          <vs-td>Courier Number</vs-td>
          <vs-td><vs-input v-model="courierNumberInput"/></vs-td>
        </vs-tr>

        <vs-tr v-if="displayCourierCompanyColumn">
          <vs-td>Courier Company</vs-td>
          <vs-td v-if="displayCourierCompanyInput">
            <v-select
              v-model="selectedCourierCompany"
              :options="courierCompanies"
            ></v-select>
          </vs-td>
          <vs-td v-if="displayCourierCompanyText">
            {{ courierCompanyToDisplay }}
          </vs-td>
        </vs-tr>

       

        <vs-tr v-if="displayCourierNumberText">
          <vs-td>Courier Number</vs-td>
          <vs-td>{{ courierNumberToDisplay }}</vs-td>
        </vs-tr>
      </vs-table>

      <div style="margin-top: 5%">
        <vs-row vs-justify="center">
          <vs-button
            type="gradient"
            :color="buttonColor"
            @click="executeAction(buttonText)"
            >{{ buttonText }}</vs-button
          >
        </vs-row>
      </div>
    </vs-popup>
        <vs-popup class="holamundo" title="" :active.sync="popupPrint" button-close-hidden="false">
      <vs-row style="margin-top:3%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
          <p>To,</p>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-align="" vs-justify="center" vs-w="9" style="margin-left: 35%">
          <div>
            <p>{{ purchase_obj.purchase.person_name }}</p>
            <p style="margin:2% 0 2% 0 ;">{{ purchase_obj.enrollment.shipping_address }}</p>
            <p>{{ purchase_obj.phone_number.last_ten_digits }}</p>
          </div>
        </vs-col>
      </vs-row>
      <br><br><br>
      <vs-row style="margin-top: 4%;">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p>From,</p>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center">
          <div>
            <p> Miles Education Private Limited,</p>
            <p>A-3/6, IDA Kukatpally,</p>
            <p>Gandhinagar, Hyderabad,</p>
            <p>Telangana, 500037,India</p>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </vs-collapse-item>
</template>

<script>
import vSelect from "vue-select";
import book from "./book";
import axios from "axios";
import constants from "../../../constants.json";
import eventbus from "../eventbus";

export default {
  
  data() {
    return {
      purchase: {
        identity: "H-23456",
        name: "John Doe",
        date_of_enrolment: 1592159400,
        date_of_payment_completion: 1592591400,
        mobile: 9652777002,
        email: "john.doe@example.com",
        delivery_address:
          "#77, Bhargavi Lodge, Pondy Bazaar, T.Nagar, Chennai, Tamil Nadu, PIN-400016",
        course: "CPA",
        books: [
          {
            id: 1,
            name: "AUD",
            edition: "2020",
            dispatch_date: 1593023400,
            courier_company: "DHL",
            courier_number: "A1324234Z",
            delivery_date: 1593023460,
            processing_batch_uuid: "fcbb8ccf-3274-4fb4-9c5f-d697aab1116f",
          },
          {
            id: 2,
            name: "FAR",
            edition: "2020",
            dispatch_date: 1593023400,
            courier_company: "DHL",
            courier_number: "A1324234Z",
            delivery_date: null,
            processing_batch_uuid: "fcbb8ccf-3274-4fb4-9c5f-d697aab1116f",
          },
          {
            id: 3,
            name: "BEC",
            edition: "2020",
            dispatch_date: 1593023400,
            courier_company: "DHL",
            courier_number: null,
            delivery_date: null,
            processing_batch_uuid: "fcbb8ccf-3274-4fb4-9c5f-d697aab1116f",
          },
          {
            id: 4,
            name: "REG1",
            edition: "2020",
            dispatch_date: null,
            courier_company: null,
            courier_number: null,
            delivery_date: null,
            processing_batch_uuid: "fcbb8ccf-3274-4fb4-9c5f-d697aab1116f",
          },
          {
            id: 5,
            name: "REG2",
            edition: "2020",
            dispatch_date: null,
            courier_company: null,
            courier_number: null,
            delivery_date: null,
            processing_batch_uuid: "fcbb8ccf-3274-4fb4-9c5f-d697aab1116f",
          },
        ],
      },
      selectedBooks: [],
      popupPrint:false,
      showDispatchButton: false,
      showCourierNumberButton: false,
      showReturnedButton: false,
      showDeliveryButton: false,
      popupActivo: false,
      popupTitle: "Dispatching Books",
      courierCompanies: [
        "DTDC",
        "ICL",
        "Trackon", 
        "UPS",
        "FedEx",
        "DHL",
        "Maruti Courier",
        "By Hand",
        "By Auto",
      ],
      selectedCourierCompany: "",
      displayCourierCompanyInput: false,
      displayCourierCompanyText: false,
      courierCompanyToDisplay: "",
      courierNumberInput: "",
      displayCourierNumberInput: false,
      displayCourierNumberText: false,
      displayCourierCompanyColumn: false,
      courierNumberToDisplay: "",
      booksToDisplay: "AUD-2020, BEC-2020",
      buttonColor: "warning",
      buttonText: "Dispatch",
    };
  },
  methods: {
    tsToDate(timestamp) {
      var ts = new Date(timestamp * 1000);
      return ts.toDateString().slice(4);
    },
    bookSelection(data) {
      let selectedBook = null;
      const books = this.purchase_obj.deliverables;
      for (let i = 0; i < books.length; i++) {
        const book = books[i];
        if (book.id === data.id) {
          selectedBook = JSON.parse(JSON.stringify(book));
          this.updateBookSelections(selectedBook, data.selected);
          break;
        }
      }
    },

    executeAction(buttonText) {
      switch (buttonText) {
        case "Dispatch":
          this.dispatchBooks();
          break;
        case "Mark Deliver":
          this.updateDeliveryStatus();
          break;
        case "Update":
          this.updateCourierNumber();
          break;
        case "Mark Returned":
          this.returnDeliverables();
          break;
        default:
          break;
      }
    },

    dispatchBooks() {
      let today = new Date();

      let params = {
        dispatch_ids: this.getBooksIds(),
        courier_company: this.selectedCourierCompany,
        address: this.purchase_obj.enrollment.shipping_address,
        from_address:
          "3rd Floor, A-3/6, IDA Kukatpally, Gandhinagar, Hyderabad, Telangana, 500037,IN",
        dispatch_date: this.datatoTimestamp(today),
      };
      axios
        .post(`${constants.SERVER_API}updateDispatchStatus`, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.popupActivo = false;
            eventbus.$emit("reload-warehouse-purchases");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateDeliveryStatus() {
      let today = new Date();
      let params = {
        dispatch_ids: this.getBooksIds(),
        delivery_date: this.datatoTimestamp(today),
      };
      axios
        .post(`${constants.SERVER_API}updateDeliveryStatus`, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.popupActivo = false;
            eventbus.$emit("reload-warehouse-purchases");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    returnDeliverables() {
      let today = new Date();
      let params = {
        dispatch_ids: this.getBooksIds(),
        returned_date: this.datatoTimestamp(today),
      };
      axios
        .post(`${constants.SERVER_API}returnDeliverables`, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.popupActivo = false;
            eventbus.$emit("reload-warehouse-purchases");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateCourierNumber() {
      let params = {
        deliverable_ids: this.getBooksIds(),
        courier_company: this.selectedCourierCompany,
        courier_number: this.courierNumberInput,
      };
      console.log(params);
      axios
        .post(
          `${constants.SERVER_API}updateCourierNumberForDeliverables`,
          params,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.popupActivo = false;
            eventbus.$emit("reload-warehouse-purchases");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getBooksIds() {
      let ids = [];
      this.selectedBooks.forEach((book) => {
        ids.push(book.id);
      });
      ids = ids.join();
      return ids;
    },

    updateBookSelections(selectedBook, selected) {
      const bookIndex = this.getBookIndex(selectedBook);

      if (bookIndex === -1 && selected === true) {
        this.selectedBooks.push(selectedBook);
      } else if (selected === false) {
        this.selectedBooks.splice(bookIndex, 1);
      }
      this.showButtons();
      console.log(this.selectedBooks);
    },

    getBookIndex(selectedBook) {
      let index = -1;
      for (let i = 0; i < this.selectedBooks.length; i++) {
        const book = this.selectedBooks[i];
        if (book.id === selectedBook.id) {
          index = i;
          break;
        }
      }
      return index;
    },

    showButtons() {
      this.showDispatchButton = false;
      this.showCourierNumberButton = false;
      this.showReturnedButton = false;
      this.showDeliveryButton = false;
      for (let i = 0; i < this.selectedBooks.length; i++) {
        const book = this.selectedBooks[i];
        if (book.dispatch_date === null) {
          this.showDispatchButton = true;
        }
        if (
          book.dispatch_date !== null &&
          (book.courier_number === null || book.courier_number === "")
        ) {
          this.showCourierNumberButton = true;
        }
        if (
          book.dispatch_date !== null &&
          book.courier_number !== null &&
          book.courier_number !== "" &&
          book.delivery_date === null
        ) {
          this.showReturnedButton = true;
          this.showDeliveryButton = true;
        }
      }
    },

    act(action) {
      switch (action) {
        case "dispatch":
          this.popupTitle = "Dispatching Books";
          this.displayCourierCompanyInput = true;
          this.displayCourierCompanyText = false;
          this.displayCourierNumberInput = false;
          this.displayCourierNumberText = false;
          this.displayCourierCompanyColumn = false;
          this.buttonColor = "warning";
          this.buttonText = "Dispatch";
          this.getDetailsForDisplay("dispatch");
          break;

        case "updateNumber":
          this.popupTitle = "Update Courier Number";

          this.displayCourierCompanyInput = true;
          this.displayCourierCompanyText = false;
          this.displayCourierNumberInput = true;
          this.displayCourierNumberText = false;
          this.displayCourierCompanyColumn = true;
          this.buttonColor = "warning";
          this.buttonText = "Update";
          this.getDetailsForDisplay("updateNumber");
          break;

        case "return":
          this.popupTitle = "Mark as Returned";

          this.displayCourierCompanyInput = false;
          this.displayCourierCompanyText = true;
          this.displayCourierNumberInput = false;
          this.displayCourierNumberText = true;
          this.displayCourierCompanyColumn = true;
          this.buttonColor = "danger";
          this.buttonText = "Mark Returned";
          this.getDetailsForDisplay("return");
          break;

        case "deliver":
          this.popupTitle = "Mark as Delivered";

          this.displayCourierCompanyInput = false;
          this.displayCourierCompanyText = true;
          this.displayCourierNumberInput = false;
          this.displayCourierNumberText = true;
          this.displayCourierCompanyColumn = true;
          this.buttonColor = "success";
          this.buttonText = "Mark Deliver";
          this.getDetailsForDisplay("deliver");
          break;

        default:
          break;
      }
      this.popupActivo = true;
    },

    getDetailsForDisplay(action) {
      this.booksToDisplay = "";
      let selectedBooks = [];
      let selectedCourierCompanies = [];
      let selectedCourierNumbers = [];
      switch (action) {
        case "dispatch":
          for (let i = 0; i < this.selectedBooks.length; i++) {
            const book = this.selectedBooks[i];
            if (book.dispatch_date === null) {
              const bookString = book.name + "-" + book.version;
              selectedBooks.push(bookString);
            }
          }
          break;

        case "updateNumber":
          for (let i = 0; i < this.selectedBooks.length; i++) {
            const book = this.selectedBooks[i];
            if (
              book.dispatch_date !== null &&
              (book.courier_number === null || book.courier_number === "")
            ) {
              const bookString = book.name + "-" + book.version;
              selectedBooks.push(bookString);
              if (
                selectedCourierCompanies.indexOf(book.courier_company) === -1
              ) {
                selectedCourierCompanies.push(book.courier_company);
              }
            }
          }
          break;

        case "return":
          for (let i = 0; i < this.selectedBooks.length; i++) {
            const book = this.selectedBooks[i];
            if (
              book.dispatch_date !== null &&
              book.courier_number !== null &&
              book.delivery_date === null
            ) {
              const bookString = book.name + "-" + book.version;
              selectedBooks.push(bookString);
              if (
                selectedCourierCompanies.indexOf(book.courier_company) === -1
              ) {
                selectedCourierCompanies.push(book.courier_company);
              }
              if (selectedCourierNumbers.indexOf(book.courier_number) === -1) {
                selectedCourierNumbers.push(book.courier_number);
              }
            }
          }
          break;

        case "deliver":
          for (let i = 0; i < this.selectedBooks.length; i++) {
            const book = this.selectedBooks[i];
            if (
              book.dispatch_date !== null &&
              book.courier_number !== null &&
              book.delivery_date === null
            ) {
              const bookString = book.name + "-" + book.version;
              selectedBooks.push(bookString);
              if (
                selectedCourierCompanies.indexOf(book.courier_company) === -1
              ) {
                selectedCourierCompanies.push(book.courier_company);
              }
              if (selectedCourierNumbers.indexOf(book.courier_number) === -1) {
                selectedCourierNumbers.push(book.courier_number);
              }
            }
          }
          break;

        default:
          break;
      }
      this.booksToDisplay = selectedBooks.join(",");
      this.courierCompanyToDisplay = selectedCourierCompanies.join(",");
      this.courierNumberToDisplay = selectedCourierNumbers.join(",");
    },
  },
  computed: {
    headerColor: function() {
      let color = "black";
      let delivery_count = 0;
      const books = this.purchase_obj.deliverables;
      for (let i = 0; i < books.length; i++) {
        const book = books[i];
        if (book.delivery_date !== null) {
          delivery_count++;
        }
      }
      if (delivery_count === books.length) {
        color = "green";
      } else if (delivery_count > 0) {
        color = "orange";
      } else {
        color = "red";
      }
      return color;
    },
  },
  components: {
    book,
    "v-select": vSelect,
  },
  props: {
    purchase_obj: {
      type: Object,
      required: true,
    },
    course: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.vs-popup--title h3,
.vs-notifications h3 {
  display: none;
}
</style>
