<template>
  <vs-list-item
    :title="title"
    :subtitle="bookDelieryStatus.text"
    :style="{ color: bookDelieryStatus.color }"
  >
    <vs-checkbox
      v-if="bookDelieryStatus.color != 'green'"
      color="danger"
      v-model="selected"
    />
  </vs-list-item>
</template>

<script>
import eventbus from "../eventbus";
export default {
  mounted() {
    eventbus.$on("reload-warehouse-purchases", () => {
      this.selected = false;
    });
  },
  data() {
    return {
      selected: false,
    };
  },
  props: ["book"],
  computed: {
    bookDelieryStatus: function() {
      let dispatchStatus = "Dispatch Date: Not yet Dispatched! | ";
      if (this.book.dispatch_date !== null) {
        dispatchStatus = `Dispatch Date: ${this.tsToDate(
          this.book.dispatch_date
        )} | `;
      }

      let courierCompany = "Courier: Not Available! | ";
      if (this.book.courier_company !== null) {
        courierCompany = `Courier Company: ${this.book.courier_company} | `;
      }

      let courierNumber = "Courier #: Not Available! | ";
      if (this.book.courier_number !== null) {
        courierNumber = `Courier #: ${this.book.courier_number} | `;
      }

      let deliveryStatus = "Delivery Date: Not Available! ";
      if (this.book.delivery_date !== null) {
        deliveryStatus = `Delivery Date: ${this.tsToDate(
          this.book.delivery_date
        )} `;
      }
      const text =
        dispatchStatus + courierCompany + courierNumber + deliveryStatus;

      let color = "green";
      if (this.book.dispatch_date === null) {
        color = "red";
      } else if (this.book.courier_number === null) {
        color = "orange";
      } else if (this.book.delivery_date === null) {
        color = "orange";
      } else {
        color = "green";
      }
      return {
        text: text,
        color: color,
      };
    },
    title: function() {
      return `${this.book.name}, ${this.book.version}`;
    },
  },
  methods: {
    tsToDate(timestamp) {
      var ts = new Date(timestamp * 1000);
      return ts.toDateString().slice(4);
    },
    emitSelections(eventName, selection_data) {
      this.$emit(eventName, selection_data);
    },
  },
  watch: {
    selected: function() {
      const selection_data = { id: this.book.id, selected: this.selected };
      this.emitSelections("book-selection", selection_data);
    },
  },
};
</script>

<style></style>
